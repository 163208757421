import React from "react"
import styled from "styled-components"
import device from "../styles/device"
import { graphql } from "gatsby"
import Loadable from "react-loadable"
import {Helmet} from "react-helmet";

//components:
import Layout from "../layout"
import ContactCta from "../components/contactCta"
import ServicesList from "../components/service"
import ServiceSlider from "../components/service-slider"
import ServiceHero from "../components/servicesHero"

//data:
import langData from '../data/languages/en';


const LoadableComponent = Loadable({
  loader: () => import("../components/service-textCarousel"),
  loading() {
    return <div>Loading...</div>
  }
})


const ServiciosContainer = styled.div`
  max-width: 1440px;
  
  margin: auto;
  padding: 100px 8.3333333% 150px;

  @media ${device.tablet}{
    padding: 140px 30px;
  }

  @media ${device.phone}{
      padding: 100px 20px;
  }
`

const ServicesRow = styled.div`    
  display: flex;
  flex-direction: column;
  margin: 0 -10px;
`


const Services = ({ data }) => {

  const {sideMenu, cookies, footer, services: {helmet: {title, meta}, heroTitle, servicesListTitle, sliderTitle, carousel}, contactBanner: {normal} } = langData;

  return (
      <Layout negative="light" sideMenu={sideMenu} cookies={cookies} footer={footer}>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={meta} />
        </Helmet>

        <ServiceHero heroTitle={heroTitle} />

        <ServiciosContainer  id="servicesContent">
          <ServicesRow>
            
            <ServicesList servicesListTitle={servicesListTitle} services={data.allWordpressWpService.edges} />

          </ServicesRow>
        </ServiciosContainer>

        <ServiceSlider sliderTitle={sliderTitle} slides={data.allWordpressWpServiceslider.edges} />

        <LoadableComponent carousel={carousel} />
      
        <ContactCta normal={normal}/>
      </Layout>
  )
}

export default Services

export const pageQuery = graphql`
  query {
    allWordpressWpService(filter: {wordpress_site: {eq: "en"}}, sort: { fields: [date], order: DESC }) {
      edges {
        node {
          title
          content
          acf {
            icon_selector
          }
        }
      }
    }

    allWordpressWpServiceslider(filter: {wordpress_site: {eq: "en"}}, sort: { fields: [date], order: ASC }) {
      edges {
        node {
          title
          content
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1280, quality: 72) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`